<template>
    <tbody>
        <tr v-if="getOption('row_type') == 'cable'" class="cable">
            <td colspan="3">
                <div class="move">
                    <svg-image
                        v-if="getChildOrderNo() > 1"
                        @click.native="moveHandler('up', getOrderNo())"
                        width="15"
                        height="15"
                        class="icon pointer up"
                        icon="arrow-right-short"
                        :label="$t('Move up')"
                    />
                    <div class="index">{{ (index+1) * 10 }}</div>
                    <svg-image
                        v-if="getCables(product).length > getChildOrderNo()"
                        @click.native="moveHandler('down', getOrderNo())"
                        width="15"
                        height="15"
                        class="icon pointer down"
                        icon="arrow-right-short"
                        :label="$t('Move down')"
                    />
                </div>
                <div class="title-wrapper">
                    <h5 class="m-0">{{ getOption('article') }}</h5>
                    <p class="mt-0">{{ getOption('model_name') }}</p>
                </div>
            </td>
            <td class="quantity">
                <SfInput
                    :label="$t('Quantity')"
                    :value="quantity"
                    name="quantity"
                    class="form__element"
                    @input="$emit('updateItemOption', { quantity: $event, delay: true })"
                />
            </td>
            <td class="price">
                <span class="currency">&euro;</span>
                <SfInput
                    :label="$t('Price')"
                    name="price"
                    class="form__element"
                    :style="stylePriceInput"
                    @input="updatePrice($event)"
                    v-model="form.price"
                />
            </td>
            <td class="action">
                <svg-image
                    class="icon pointer remove"
                    icon="close"
                    :label="$t('Remove')"
                    width="15"
                    height="15"
                    @click.native="removeHandler"
                />
            </td>
        </tr>
        <tr v-else class="product">
            <td>
                <div class="move">
                    <svg-image
                        v-if="getOrderNo() > 10"
                        @click.native="moveHandler('up', getOrderNo())"
                        width="15"
                        height="15"
                        class="icon pointer up"
                        icon="arrow-right-short"
                        :label="$t('Move up')"
                    />
                    <div class="index">{{ (index+1) * 10 }}</div>
                    <svg-image
                        v-if="!last"
                        @click.native="moveHandler('down', getOrderNo())"
                        width="15"
                        height="15"
                        class="icon pointer down"
                        icon="arrow-right-short"
                        :label="$t('Move down')"
                    />
                </div>
                <div class="title-wrapper">
                    <h4 class="mt-0 mb-2">{{ getOption('article') }}</h4>
                    <p class="mt-0 mb-0">{{ getOption('model_name') }}</p>
                    <p v-if="getOption('luminous_flux')" class="mt-0 lumens">{{ getOption('luminous_flux') }} lm</p>
                </div>
            </td>
            <td class="requested_delivery_date">
                <vue-datepicker
                    :format="dateFormat"
                    :clearable="true"
                    class="form__element"
                    :label="$t('Requested delivery date')"
                    :value="getOption('requested_delivery_date')"
                    @input="$emit('updateItemOption', { requested_delivery_date: $event, delay: false })"
                />
            </td>
            <td class="warranty">
                <SfSelect
                    v-if="warranty.length"
                    :value="getOption('warranty_id')"
                    :placeholder="$t('Select...')"
                    :label="$t('Warranty')"
                    name="warranty"
                    class="form__element form__select sf-select--underlined"
                    @input="$emit('updateItemOption', { warranty_id: $event, delay: false })"
                >
                    <SfSelectOption
                        v-for="item in warranty"
                        :key="item.id"
                        :value="item.id"
                    >
                        {{ item.name }}
                    </SfSelectOption>
                </SfSelect>
            </td>
            <td class="quantity">
                <SfInput
                    :label="$t('Quantity')"
                    :value="quantity"
                    name="quantity"
                    class="form__element"
                    @input="$emit('updateItemOption', { quantity: $event, delay: true })"
                />
            </td>
            <td class="price">
                <span class="currency">&euro;</span>
                <SfInput
                    :label="$t('Price')"
                    name="price"
                    class="form__element"
                    :style="stylePriceInput"
                    @input="updatePrice($event)"
                    v-model="form.price"
                />
            </td>
            <td class="action">
                <svg-image
                    class="icon pointer remove"
                    icon="close"
                    :label="$t('Remove')"
                    width="15"
                    height="15"
                    @click.native="removeHandler"
                />
            </td>
        </tr>
        <tr v-if="getOption('row_type') == 'product'">
            <td colspan="5">
                <div class="editable" v-if="hasPermissionToEdit">
                    <SfTextarea
                        name="description"
                        :rows="2"
                        :cols="100"
                        wrap="soft"
                        :value="form.description"
                        @input="updateDescription($event)"
                    />
                </div>
                <div class="editable" v-else>
                    <span v-html="form.description"></span>
                </div>           
            </td>
        </tr>
        <tr v-if="getOption('row_type') == 'product'" class="more-options" v-show="product.product.sku != 'cable'">
            <td colspan="5" class="pb-3 pl-2">
                <SfInput
                    :value="getOption('product_reference')"
                    name="product_reference"
                    :label="$t('Product reference')"
                    class="form__element col-4"
                    @input="$emit('updateItemOption', { product_reference: $event, delay: true })"
                />
                <span
                    :title="$t('Datasheet')"
                    @click="$emit('downloadDatasheet', getOption('article'), getOption('model_name'), getOption('product_reference'))"
                    class="download-ico ml-3 mr-2 mt-1"
                />
                <span
                    class="button button-secondary small"
                    @click="openCableModal"
                >
                    {{ $t('Add cable') }}
                </span>
                <span
                    v-if="Object.keys(JSON.parse(getOption('dimming'))).length > 0"
                    class="button small ml-2"
                    :class="getDimmingState(getOption('dimming')) ? 'button-secondary' : 'button-primary'"
                    @click="$emit('openDimmingModal', product, getOption('dimming'))"
                >
                    {{ $t('Configure dimming') }}
                </span>
            </td>
        </tr>
    </tbody>
</template>

<script>
import { SfInput, SfTextarea } from '@storefront-ui/vue';
import { reactive, computed, ref, toRef, watch, useContext } from '@nuxtjs/composition-api';
import { useUser } from '@/composables';
import _debounce from 'lodash.debounce';
import SfSelect from '~/components/General/SfSelect/SfSelect.vue';
import moment from 'moment';
import VueDatepicker from '~/components/Common/Calendar/VueDatepicker.vue';

export default {
	name: 'CartProducts',
	components: {
		SfInput,
		SfSelect,
		SfTextarea,
		VueDatepicker
	},
	model: {
		prop: 'qty',
	},
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		totalItems: {
			type: [Number, String],
			default: 0,
		},
		index: {
			type: [Number, String],
			default: 0,
		},
		last: {
			type: Boolean,
			default: false,
		},
		product: {
			type: Object
		},
		title: {
			type: String,
			default: '',
		},
		price: {
			type: [Number, String],
			default: null,
		},
		qty: {
			type: [Number, String],
			default: 1,
		},
		configuration_options: {
			type: Array,
			default: () => [],
		},
		cart_options: {
			type: Object,
			default: () => {},
		},
		selected_options: {
			type: Object,
			default: () => {},
		}
	},
	methods: {
		getTomorrow() {
			return moment(new Date()).add(1, 'days').format('YYYY-MM-DD');
		}
	},
	setup(props, { emit }) {
		const { app } = useContext();
		const { hasPermission } = useUser();

		const apiState = app.$vsf.$magento.config.state;
		const dateFormat = ref('YYYY-MM-DD');

		const getAttributes = (product) => product.configurable_options || [];
		const removeHandler = () => emit('click:remove');
		const actionsHandler = () => emit('click:actions');

		const priceInput = ref(50 + props.price.toString().length * 7);

		const getOption = (type) => {
			const option = props.configuration_options.find(e => e.code == type);

			return option?.value;
		};

		const form = reactive({
			price: props.price,
			description: getOption('description'),
		});

		const updatePrice = (price) => {
			priceInput.value = 50 + price.toString().length * 7;
			emit('updateItemOption', { price: price, delay: true });
		};

		const getOrderNo = () => {
			return getOption('order_no');
		};

		const getChildOrderNo = () => {
			return getOption('order_no').split('.')[1];
		};

		const openCableModal = () => {
			emit('openCableModal', getOption('order_no'));
		};

		const moveHandler = (position, order_no) => {
			const move = order_no % 1 != 0 ? 0.1 : 10;

			if (position == 'up') {
				order_no = (order_no * 1) - move;
			} else {
				order_no = (order_no * 1) + move;
			}

			emit('updateItemOption', { order_no: order_no, delay: false });
		};

		const getCables = (product) => {
			return props.items.filter(p => p.configuration_options.filter(
				opt => opt.code == 'parent_order_no' && opt.value == parseInt(getOption('order_no'))
			).length);
		};

		const stylePriceInput = computed(() => 'min-width: 75px; width: ' + priceInput.value + 'px');

		const getDimmingState = (data) => {
			const dimming = JSON.parse(data);

			return dimming.validated;
		};

		const updateDescription = async (data) => {
			emit('updateItemOption', { description: data, delay: true });
			form.description = data;
		};

		return {
			quantity: computed(() => typeof props.qty === 'string' ? Number(props.qty) : props.qty),
			warranty: computed(() => props.cart_options.warranty ? props.cart_options.warranty.filter(w => w.products.includes(props.product.product.uid)) : []),
			hasPermissionToEdit: computed(() => { return hasPermission('edit article description') }),
			dateFormat: computed(() => apiState.getLocale() == 'us' ? 'MM-DD-YYYY' : 'DD-MM-YYYY'),
			getAttributes,
			removeHandler,
			actionsHandler,
			getOption,
			form,
			moveHandler,
			openCableModal,
			updatePrice,
			stylePriceInput,
			getCables,
			getOrderNo,
			getChildOrderNo,
			getDimmingState,
			updateDescription
		}
	},
};
</script>
<style lang="scss" scoped>
thead {
    th {
        text-align: left;
        border-bottom: 1px solid #dcdfe6;
    }
}

tbody {
    tr {
        &.cable {
            td {
                padding-left: 20px;

                &.quantity,
                &.price {
                    padding-left: 0;
                }
            }
        }

        &.more-options {
            td {
                padding-left: 20px;
            }
        }

        &.product {
            td {
                padding-top: 20px;
                border-top: 1px solid var(--c-light);

                &.requested_delivery_date {
                    padding-top: 0;
                    width: 250px;
                }

                &.quantity {
                    width: 130px;
                }

                &.warranty {
                    width: 180px;
                    padding-top: 29px !important;

                    .v-select {
                        width: 150px;
                        margin-top: -15px;
                    }
                }
            }
        }
    }

    td {
        padding-right: 15px;
    }

    &:first-of-type {
        tr {
            &.product {
                td {
                    border-top: none;
                }
            }
        }
    }
}

.move {
    float: left;
    position: relative;
    top: 0;
    left: -5px;
    width: 20px;
    color: var(--_c-dark-secondary);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .index {
        font-size: 12px;
        line-height: 12px;
    }

    .up {
        transform: rotate(-90deg);
    }

    .down {
        transform: rotate(90deg);

    }
}

.title-wrapper {
    float: left;
}

.price {
    width: 130px;

    .currency {
        position: absolute;
        margin-top: 16px;
    }

    ::v-deep .sf-input {
        input {
            padding-left: 15px;
        }
    }
}

.action {
    position: relative;
    width: 50px;

    .edit {
        position: absolute;
        right: 30px;
        top: 18px;
    }

    .remove {
        position: absolute;
        right: 5px;
        top: 30px;
    }
}

.product {
    .action {
        .remove {
            top: 47px;
        }
    }
}

.sf-quantity-selector {
    background: none;
}

.title-wrapper {
    p {
        font-size: 14px;
        line-height: 15px;
    }
}

.editable {
    display: flex;
    align-items: flex-start;
    padding-left: 20px;

    span {
        margin-bottom: 15px;
        width: 75%;
    }
}

.sf-textarea {
    textarea {
        font-size: 14px;
        max-width: 850px;
    }
}

.lumens {
    font-size: 12px;
    color: var(--c-gray);
    letter-spacing: -0.4px;    
}

.download-ico {
    position: relative;
    top: 4px;
}
</style>
