<template>
    <div class="get-in-touch col-12 col-lg-4">
        <h1>{{ $t('Get in Touch') }}</h1>
        <div>    
            <div class="sales col-12 col-md-6 col-lg-12">
                <h3>{{ $t('Office') }}</h3>
                <p>
                    Bukultu iela 11, Riga, LV-1005, Latvia<br/>
                    {{ $t('Phone') }}: +371 67 383 023<br/>
                    {{ $t('Mail') }}: office@vizulo.com
                </p>
                <h3>{{ $t('Sales') }}</h3>

                <p v-if="apiState.getStore() == 'pl'">
                    Piotr Kapkowski<br/>
                    {{ $t('Mail') }}: piotr.kapkowski@vizulo.com<br/>
                    {{ $t('Phone') }}: +48 518 927 069
                </p>
                <p v-else>
                    Bukultu iela 11, Riga, LV-1005, Latvia<br/>
                    +371 67 383 023<br/>
                </p>
                <h3>{{ $t('Lighting design team') }}</h3>
                <p>
                    {{ $t('Enquiries regarding photometric files and lighting calculations.') }}<br/>
                    Bukultu iela 11, Riga, LV-1005, Latvia<br/>
                    {{ $t('Phone') }}: +371 29193164<br/>
                    <a href="https://form.asana.com/?k=r0RwfXzEZW4eBJkJuQZz7A&d=251190582465178" target="_blank" class="request-link">{{ $t('Request form') }}</a> 
                </p>
                
                <div>
                    <div 
                        class="arrow" 
                        @click="openMap(salesUrl)"
                    >
                        <svg-image
                            class="arrow-link"
                            height="22"
                            icon="arrow-link"
                            width="22"
                        />
                    </div>
                    <a :href="salesUrl" target="_blank" class="map-link">{{ $t('Go to Map') }}</a>
                </div>
            </div>
            <div class="production col-12 col-md-6 col-lg-12">
                <h3>{{ $t('Production') }}</h3>
                <p>
                    Laucu Lejas, Iecava, LV-3913, Latvia<br/>
                    {{ $t('Phone') }}: +371 67 383 024<br/>
                </p>


                <div>
                    <div 
                        class="arrow"
                        @click="openMap(productionUrl)"
                    >
                        <svg-image
                            class="arrow-link"
                            height="22"
                            icon="arrow-link"
                            width="22"
                        />
                    </div>
                    <a :href="productionUrl" target="_blank" class="map-link">{{ $t('Go to Map') }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, useContext } from '@nuxtjs/composition-api';

export default defineComponent({
	name: 'GetInTouch',
	data() {
		return {
			productionUrl: 'https://www.google.com/maps/place/%22VIZULO%22,+SIA/@56.6115388,24.2190229,17z/data=!3m1!4b1!4m5!3m4!1s0x46e8d15e6e7940d7:0xe64ce2a44fe98cee!8m2!3d56.6115388!4d24.2212116',
			salesUrl: 'https://www.google.com/maps/place/Bukultu+iela+11,+Zieme%C4%BCu+rajons,+R%C4%ABga,+LV-1005/@56.9780617,24.1139092,17z/data=!3m1!4b1!4m5!3m4!1s0x46eecfa32d23c155:0x574baba6c98116dc!8m2!3d56.9780617!4d24.1160979',
		}
	},
	methods: {
		openMap(url) {
			window.open(url);
		}
	},
	setup() {
        const { app } = useContext();
        const apiState = app.$vsf.$magento.config.state;

        return {
            apiState
        }
    },
});
</script>

<style lang="scss" scoped>
.get-in-touch {
    padding: 0;
    line-height: 1.8;
    font-size: 16px; 

    h1 {
        font-size: 28px;
        line-height: 38px;
        font-weight: 400;
        padding: 0 15px;
    }

    h3 {
        margin-bottom: 0;

        @media (max-width:991px) {
            margin: 0;
            line-height: 1;
        }
    }

    .production, 
    .sales {
        margin: 44px 0 0;

        p {
            margin-top: 0;
        }

        a.map-link {
            font-size: 20px;
            padding-left: 6px;
            position: relative;
            top: 3px;

            @media (max-width:991px) {
                font-size: 16px;
            }
        }

        a.map-link:hover {
            cursor: pointer;
        }

        .arrow:hover {
            cursor: pointer;
        }

        a.request-link {
            text-decoration: underline;
        }

        div {
            display: flex;
            align-items: center;
        }
    }
}
</style>