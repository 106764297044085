<template>
	<div>
		<div class="main">
			<div class="container">
				<div class="row">
					<div class="col-12">
						<div class="links">

                            <SfLink
                                v-for="(category, index) in categories"
                                :key="index"
                                class="header-nav-item"
                                :link="localePath(getAgnosticCatLink(category))"
                            >
                                <svg-image
                                    class="arrow-link"
                                    height="22"
                                    icon="arrow-link"
                                    width="22"
                                />
                                {{ category.label + ' ' + $t('luminaires') }}
                            </SfLink>
						</div>
					</div>
				</div>
                <div class="fixed-lapwing-banner">
                    <LapWingBanner/>
                </div>
				<div class="fixed-bottom">
					<Scroll/>
				</div>
			</div>
			<Video
				ref="vimeo"
				id="home-video"
				:params="{background: 1, allowfullscreen: 0, autoplay: 1}"
				src="https://player.vimeo.com/video/990443564?h=5984ee53aa&amp;background=1&amp;autoplay=1&amp;app_id=58479"
			/>
		</div>
		<div class="explore">
			<Cite :text="aboutVizulo" />
		</div>
		<div class="explore">
			<div v-for="item of productsWithVideos.items" :key="item.product_id">
				<div class="container">
					<div class="row">
						<div class="col-12">
							<div class="explore__data">
								<h3>{{ item.title }}</h3>
								<p class="read-more">{{ item.description | strippedContent }}</p>
							</div>
						</div>
					</div>
				</div>
				<Video
                    :key="item.product_id"
					:src="item.video"
					ref="vimeo"
				/>
			</div>
		</div>
		<div class="about">
			<div class="container">
				<div class="row">
					<div class="col-12 col-lg-8">
						<div class="about-text">
							<h5>{{ $t('About Vizulo') }}</h5>
							<p>{{ aboutVizulo }}</p>
						</div>
					</div>
					<div class="col-12 col-lg-4">
						<div class="about-stats">
							<div class="number">{{ totalCountries }}</div>
							<div class="link">
								<h6>{{ $t('Countries') }}</h6>
								<Link
									:icon="true"
									:link="localePath('references')"
									:text="$t('Go to References')"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="module">
import {
	ref,
	defineComponent,
	useFetch,
	computed,
    onMounted
} from '@nuxtjs/composition-api';

import { useUiHelpers, useConfig } from '~/composables';
import useCategory from '@/modules/catalog/category/components/category/useCategory';
import Video from '~/components/Common/Video.vue';
import Scroll from '~/components/Common/Scroll.vue';
import LapWingBanner from '~/components/Common/LapWingBanner.vue';
import Cite from '~/components/Common/Cite.vue';
import { useVideos } from '~/modules/vizulo/videos';
import Link from '~/components/Common/Link.vue';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { categoryGetters, storeConfigGetters } from '~/getters';
import { SfLink } from '@storefront-ui/vue';

export default defineComponent({
	name: 'Home',
	components: {
		Cite,
		Scroll,
		Video,
		useVideos,
		Link,
        SfLink,
        LapWingBanner
	},
	head() {
		const title = 'Vizulo';
		const meta = [];

		return {
			title,
			meta,
		};
	},
	filters: {
		strippedContent: function (string) {
			return string.replace(/<\/?[^>]+>/ig, ' ');
		}
	},
	setup() {
		const { getAgnosticCatLink } = useUiHelpers();
		const { getVideos } = useVideos();
		const { config } = useConfig();
		const { addTags } = useCache();

		const { categoryTree, loadCategoryTree } = useCategory();

		const productsWithVideos = ref([]);

		const categories = ref([]);

		onMounted(async () => {
			if (!categoryTree.value) {
				await loadCategoryTree();
			}

			categories.value = categoryGetters
				.getCategoryTree(categoryTree.value)
				?.items?.filter((c) => c.count > 0 && c.id != 68 && c.id != 74);

			productsWithVideos.value = await getVideos();

			const productTags = productsWithVideos.value.items.map((product) => ({
				prefix: CacheTagPrefix.Product,
				value: product.uid,
			}));

			const tags = [{ prefix: CacheTagPrefix.View, value: 'home' }];

			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			addTags([...tags, ...productTags]);
		});

		return {
			categories,
			categoryTree,
			getAgnosticCatLink,
			productsWithVideos,
			aboutVizulo: computed(() => storeConfigGetters.getAboutVizulo(config.value) || ''),
			totalCountries: computed(() => storeConfigGetters.getTotalCountries(config.value) || ''),
		};
	},
});

</script>

<style lang="scss" scoped>
.links {
	height: 100%;
	position: absolute;
	z-index: 9;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;

	@media (max-width: 991px) {
		display: none;
	}

    .header-nav-item {
        float: left;
        display: block;
        border: 1px solid var(--_c-dark-primary);
        position: relative;
        margin: 0 25px 0 0;
        color: var(--c-white);
        font-size: 16px;
        letter-spacing: .25px;
        line-height: 28px;
        text-decoration: none;
        padding: 20px 20px 20px 40px;
        transition: all .2s ease-in-out;
        font-family: var(--font-family--secondary);
        font-weight: 700;

        .svg-image.arrow-link {
            position: absolute;
            top: 23px;
            left: 15px;
            color: var(--c-white);
        }

        &:hover {
            border: 1px solid var(--c-white);
            background: var(--c-white);
            transition: all .2s ease-in-out;
            color: var(--c-link);

            .svg-image.arrow-link {
                color: var(--c-link);
            }
        }
    }
}

.main {
	width: 100%;
	height: 100vh;
	position: relative;
	padding: 52px 0 0 0;
	overflow: hidden;
	margin-bottom: 120px;

	@media (max-width: 992px) {
		margin-top: 70px;
		margin-bottom: 35px;
		height: 118.25vw;
	}

	& > .container {
		height: 100%;
	}
}

#home-video {
	width: 100vw;
	height: 56.25vw;
	min-height: 100vh;
	min-width: 100vw;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: black;

	@media (max-width: 992px) {
		min-width: 100vh;
	}

	@media screen and (min-aspect-ratio: 16/10) {
		width: 112vw;
	}

	@media screen and (max-aspect-ratio: 4/3) {
		width: 135vw;
	}

	.embed-responsive {
		iframe,
		object,
		embed {
			width: 100vw;

			@media (min-width: 992px) and (max-aspect-ratio: 4/3) {
				width: 112vw;
			}
		}
	}
}

.explore {
	margin-bottom: 120px;

	@media (max-width: 992px) {
		margin-bottom: 35px;
	}

	& > div {
		margin-top: 120px;

		@media (max-width: 992px) {
			margin-top: 35px;
		}

	}
}

.read-more {
	font-size: 20px;
	line-height: 28px;
	margin-bottom: 120px;

	@media (max-width: 992px) {
		margin-bottom: 35px;
		font-size: 16px;
		line-height: 22px;
	}
}

.fixed-bottom {
	position: absolute;
	bottom: 75px;
	z-index: 10;
}

.fixed-lapwing-banner {
	position: absolute;
	bottom: 275px;
	z-index: 10;
    color: var(--c-white);
}

.about {
	margin-bottom: 120px;

	@media (max-width: 992px) {
		margin-bottom: 35px;
	}

	&-text {
		@media (min-width: 992px) {
			padding-left: 30px;
		}

		h5 {
			font-size: 20px;
			letter-spacing: 0.25px;
			line-height: 28px;
			margin: 0 0 28px;

			@media (max-width: 992px) {
				font-size: 16px;
				line-height: 24px;
				letter-spacing: 0.2px;
				margin: 0 0 20px;
			}
		}

		p {
			font-size: 20px;
			line-height: 28px;
			margin: 0 0 28px;

			@media (max-width: 992px) {
				font-size: 18px;
				line-height: 25px;
			}
		}
	}

	&-stats {
		overflow: hidden;

		@media (min-width: 992px) {
			padding-left: 30px;
		}

		@media (max-width: 992px) {
			padding-bottom: 36px;
		}

		.number {
			float: left;
			font-size: 60px;
			font-weight: 700;
			line-height: 68px;
			margin-right: 32px;

			@media (max-width: 992px) {
				font-size: 32px;
				line-height: 40px;
			}
		}

		.link {
			float: left;
			padding-top: 20px;

			@media (max-width: 992px) {
				padding-top: 7px;
			}

			h6 {
				font-size: 16px;
				font-weight: 700;
				letter-spacing: 0.2px;
				line-height: 18px;
				margin: 0 0 6px;

				@media (max-width: 992px) {
					font-size: 14px;
					letter-spacing: 0.18px;
				}
			}
		}
	}
}
</style>
