<template>
    <div class="mb-4 pt-6">
        <div class="documents-container">
            <div @click="expanded = !expanded" class="basic-flex padding-top-bot flex-between">
                <div class="basic-flex">
                    <svg-image
                        class="download-file"
                        icon="download-file"
                        width="28"
                        height="28"
                    />
                    <h3 class="">{{$t('Download Documents')}}</h3>
                </div>
                <div class="hide-mobile">
                    <svg-image
                        v-if="expanded"
                        icon="chevron_up"
                        width="18"
                        height="18"
                    />
                    <svg-image
                        v-else
                        icon="chevron_down"
                        width="18"
                        height="18"
                    />
                </div>
            </div>
            <div v-if="expanded" class="documents">
                <div class="list-item" v-for="item of documents.items" :key="item.attachment_id">
                    <svg-image
                        icon="external-link"
                        width="16"
                        height="16"
                    />
                    <a :href="item.fileName" target="_blank">{{ item.title }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useDocuments } from '~/composables/useDocuments';
import {
	ref,
	onMounted,
    useRoute
} from '@nuxtjs/composition-api';

export default {
	components: {
		useDocuments
	},
	props: {
		productSku: {
			type: String,
		},
        hasOneImage: {
            type: Boolean,
        }
	},
	setup(props) {
		const { getAllDocuments } = useDocuments();
		const documents = ref([]);
		const expanded = ref(true);
        const route = useRoute();
        const { query } = route.value;
        const idsToExcludeForEdgarsSpecOrder = [1733, 1368, 1369, 1787, 1788, 2034]; // Specifically For F210010001 Order from Edgars Kuks -> Remove in 2024 for sure
        const excludeForAllExceptMRSFT = [2072, 2073]; // Jevgenija spec MRSFT
        const excludeSpeciaLMRSFT = 1937;

		onMounted(async () => {
			// When product form will be finished, assign dynamically
			documents.value = await getAllDocuments(props.productSku);

            // Exception for specific Tender that needs to be removed later.
            if (query.so == 'F210010001' || query.so == 'T21000XXXXX') {
                if (props.hasOneImage) {
                    documents.value.items = documents.value.items?.filter(document => {
                        return document.attachmentId !== excludeSpeciaLMRSFT;
                    })
                } else {
                    documents.value.items = documents.value.items?.filter(document => {
                        return !idsToExcludeForEdgarsSpecOrder.includes(document.attachmentId);
                    })
                }
            } else {
                documents.value.items = documents.value.items?.filter(document => {
                    return !excludeForAllExceptMRSFT.includes(document.attachmentId);
                })
            }
		});

		return {
			documents,
			expanded,
		};
	}
};
</script>

<style lang="scss" scoped>

</style>