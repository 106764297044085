<template>
    <div class="center">
        <div class="w-100" v-if="activeStep=='step_6'">
            <div class="w-100 mb-7" :class="{container: !isMobileView}">
                <div class="flex-column mb-5" v-if="reactiveGeneralValues.general.chosenConfiguration">
                    <div>
                        <span>{{ $t('Configuration completed') }}</span>
                    </div>
                </div>
                <div class="base-attribute flex-between"  v-if="reactiveProductConfigurator.downloads.length > 0 && reactiveGeneralValues.general.chosenConfiguration">
                    <p class="vw-10">{{ $t('Download') }}:</p>
                    <ul class="options">
                        <li
                            class="option"
                            @click="openSpecificPopUp('datasheet')"
                            :class="{ 'inactive-sheet': reactiveProductConfigurator.downloads.length === 0 }"
                        >
                            <span>{{ $t('Datasheet') }}</span>
                        </li>
                        <li
                            class="option"
                            @click="openSpecificPopUp('ldt')"
                            :class="{ 'inactive-sheet': !selectedValues.step_6.filesAvailable.includes('ldt') }"
                        >
                            <span>{{ $t('LDT file') }}</span>
                        </li>
                        <li
                            class="option"
                            @click="openSpecificPopUp('ies')"
                            :class="{ 'inactive-sheet': !selectedValues.step_6.filesAvailable.includes('ies') }"
                        >
                            <span>{{ $t('IES file') }}</span>
                        </li>
                    </ul>
                </div>
                <SkeletonLoader v-if="loading.steps"/>
                <div class="order-info" v-if="reactiveGeneralValues.general.chosenConfiguration && !loading.steps">
                    <div class="product-image relative-pos">
                        <div class="color-indicator-container" v-if="!activeImagePath.includes('lens')">
                            <div
                                class="color-indicator"
                                :style="{'background-color': selectedValues.step_5.color.hex_code}"
                            >
                            </div>
                            <p>{{ selectedValues.step_5.color.number }}</p>
                        </div>
                        <div class="flex-center">
                            <img
                                v-if="selectedValues.step_6.productImagePath || activeImagePath"
                                v-bind:src="`${apiVizuloStorageUrl}${ activeImagePath ? activeImagePath : selectedValues.step_6.productImagePath}`"
                                alt="product image"
                            >
                            <img
                                v-else
                                class="w-100 console-image"
                                :src="require(`~/assets/images/consoles/console-0.svg`)"
                                alt="product image"
                            />
                        </div>
                        <div class="gallery-bar">
                            <div class="m-0 p-0 flex">
                                <div class="original-image p-1 flex-center" :class="{ active: !activeImagePath || activeImagePath === selectedValues.step_6.productImagePath }">
                                    <img
                                        v-if="selectedValues.step_6.productImagePath"
                                        v-bind:src="`${apiVizuloStorageUrl}${selectedValues.step_6.productImagePath}`"
                                        @click="activeImagePath = selectedValues.step_6.productImagePath"
                                        alt="product image"
                                    >
                                    <img
                                        v-else
                                        :src="require(`~/assets/images/consoles/console-0.svg`)"
                                        alt="product image"
                                    />
                                </div>
                                <div class="original-image p-1 flex-center" :class="{ active: activeImagePath === selectedValues.step_2.lens.file_path }" @click="activeImagePath = selectedValues.step_2.lens.file_path">
                                    <img :src="apiVizuloStorageUrl + selectedValues.step_2.lens.file_path">
                                </div>
                                <div
                                    class="original-image p-1 flex-center"
                                    v-if="selectedValues.step_2.lens.isoline_diagram_file_path"
                                    @click="activeImagePath = getIsolineFilePath(selectedValues.step_2.lens.isoline_diagram_file_path)"
                                    :class="{ 'active': activeImagePath === getIsolineFilePath(selectedValues.step_2.lens.isoline_diagram_file_path) }"
                                >
                                    <img :src="apiVizuloStorageUrl + getIsolineFilePath(selectedValues.step_2.lens.isoline_diagram_file_path)">
                                </div>

                            </div>
                        </div>
                        <div>
                            <div class="flex align-items-center">
                                <p class="m-0">{{ $t('Tender text') }}:</p>
                                <button @click="copyText(); copied = true" class="btn small ml-3">
                                    <p class="m-0" v-if="!copied">{{ $t('copy') }}</p>
                                    <p class="m-0" v-else>{{ $t('copied') }}</p>
                                </button>
                            </div>
                            <p v-if="selectedValues.step_6.tenderText">
                                <ReadMore :maxChars="360" :text="selectedValues.step_6.tenderText" />
                            </p>
                        </div>
                    </div>
                    <div class="flex general-info">
                        <div class="flex product-info">
                            <div>
                                <div class="flex p-0">
                                    <p class="w-8">{{ $t('Product') }}:</p>
                                    <p v-if="reactiveGeneralValues.general.productName" class="ml-2">{{reactiveGeneralValues.general.productName}}</p>
                                </div>
                                <div class="flex p-0">
                                    <div class="flex-center" v-if="getProductVersionIds(selectedValues.step_1.productVersions).length > 0 && getProductVersionIds(selectedValues.step_1.productVersions)[0] !== 71">
                                        <p class="w-8 m-0">{{ $t('Product version') }}:</p>
                                        <span :class="{ 'ml-2' : version.selected }" v-for="(version, key,index) in selectedValues.step_1.productVersions" :key="key">
                                            <span v-if="version.selected">
                                                <span>{{ version.name }}</span>
                                                <span v-if="index < getProductVersionIds(selectedValues.step_1.productVersions).length-1">,</span>
                                            </span>
                                        </span>
                                    </div>
                                    <div class="flex" v-else>
                                        <p class="w-8 mt-0">{{ $t('Product version') }}:</p>
                                        <p class="ml-2 mt-0">{{ $t('Standard') }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="article">
                                <div class="flex m-0 p-0">
                                    <p class="w-8">{{ $t('Article') }}:</p>
                                    <div class="flex-center relative-pos ml-2">
                                        <span v-if="reactiveGeneralValues.general.article">
                                            {{ reactiveGeneralValues.general.article }}
                                        </span>
                                        <button v-else-if="!isAuthenticated" @click="checkIfLogginNeeded('reserve')" class="btn small show-reserve-tooltip mx-0">
                                            {{ $t('Login to reserve') }}
                                        </button>
                                        <button  v-else @click="checkIfLogginNeeded('reserve')" class="btn small show-reserve-tooltip mx-0">
                                            {{ $t('Reserve') }}
                                        </button>
                                        <div class="typical-attribute-tooltiptext small-button">
                                            {{ $t('Saves product configuration and generates an article that can be used to order configured product.') }}
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-start m-0 p-0">
                                    <p class="w-8 m-0">{{ $t('Model number') }}:</p>
                                    <span class="ml-2">{{ modelNameString }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="info">
                            <div class="attributes">
                                <div class="flex" v-if="selectedValues.general.chosenConfiguration && selectedValues.general.chosenConfiguration.flux > 0">
                                    <p>{{ $t('Flux') }}:</p>
                                    <p class="ml-2">{{ selectedValues.general.chosenConfiguration.flux }} lm</p>
                                </div>
                                <div class="flex" v-if="selectedValues.general.chosenConfiguration && selectedValues.general.chosenConfiguration.power > 0">
                                    <p>{{ $t('Power') }}:</p>
                                    <p class="ml-2">{{ selectedValues.general.chosenConfiguration.power }} W</p>
                                </div>
                                <div class="flex" v-if="selectedValues.general.chosenConfiguration && selectedValues.general.chosenConfiguration.luminous_efficacy > 0">
                                    <p>{{ $t('Efficacy') }}:</p>
                                    <p class="ml-2">{{ selectedValues.general.chosenConfiguration.luminous_efficacy }} lm/W</p>
                                </div>
                                <div class="flex" v-if="selectedValues.general.chosenConfiguration && selectedValues.general.chosenConfiguration.power_factor > 0">
                                    <p>{{ $t('Power factor') }}:</p>
                                    <p class="ml-2">{{ selectedValues.general.chosenConfiguration.power_factor }}</p>
                                </div>
                                <div class="flex" v-if="selectedValues.step_2.cri !== null">
                                    <p>{{ $t('Color rendering index') }}:</p>
                                    <p class="ml-2">{{ selectedValues.step_2.cri.name }}</p>
                                </div>
                                <div class="flex" v-if="selectedValues.step_2.colorTemperature !== null">
                                    <p>{{ $t('Color temperature') }}:</p>
                                    <p class="ml-2">{{ selectedValues.step_2.colorTemperature.name_with_label }}</p>
                                </div>
                                <div class="flex">
                                    <p>{{ $t('Constant light output') }}:</p>
                                    <p class="ml-2" :class="{'less-visible': !selectedValues.step_2.constantLightOutput}">{{ selectedValues.step_2.constantLightOutput == false ? 'No': 'Yes' }}</p>
                                </div>
                                <div class="flex" v-if="selectedValues.step_2.constantLightOutput">
                                    <p>{{ $t('Initial output') }}:</p>
                                    <p class="ml-2">{{ selectedValues.step_2.initialOutput }}%</p>
                                </div>
                                <div class="flex" v-for="(item, index) in selectedValues.step_3.sockets" :key="index">
                                    <p>{{ $t('Socket') }} {{index}}:</p>
                                    <p class="ml-2" :class="{'less-visible': item.name.toLowerCase() == 'none'}">{{ item.name }}</p>
                                </div>
                                <div class="flex" v-if="selectedValues.step_3.dimming">
                                    <p>{{ $t('Dimming') }}:</p>
                                    <p class="ml-2" :class="{'less-visible': selectedValues.step_3.dimming.name.toLowerCase() == 'no dimming'}">{{ selectedValues.step_3.dimming.name }}</p>
                                </div>
                                <div class="flex" v-if="selectedValues.step_3.insulation && storeCode !== 'us'">
                                    <p>{{ $t('Insulation') }}:</p>
                                    <p class="ml-2">{{ selectedValues.step_3.insulation.name }}</p>
                                </div>
                                <div class="flex">
                                    <p>{{ $t('Surge protection device') }}:</p>
                                    <p class="ml-2" :class="{'less-visible': !selectedValues.step_3.surgeProtection}">{{ selectedValues.step_3.surgeProtection == false ? 'No': 'Yes'  }}</p>
                                </div>
                                <div class="flex">
                                    <p>{{ $t('Thermal protection with NTC') }}:</p>
                                    <p class="ml-2" :class="{'less-visible': !selectedValues.step_3.thermalProtectionNTC}">{{ selectedValues.step_3.thermalProtectionNTC == false ? 'No': 'Yes' }}</p>
                                </div>
                                <div class="flex" v-if="selectedValues.step_1.console !== null">
                                    <p>{{ $t('Console') }}:</p>
                                    <p class="ml-2">{{ selectedValues.step_1.console.name }}</p>
                                </div>
                                <div class="flex" v-if="selectedValues.step_1.mounting !== null">
                                    <p>{{ $t('Mounting') }}:</p>
                                    <p class="ml-2">{{ selectedValues.step_1.mounting.name }}</p>
                                </div>
                                <div class="flex" v-if="selectedValues.step_5.color !== null">
                                    <p>{{ $t('Color') }}:</p>
                                    <p class="ml-2">{{ selectedValues.step_5.color.number }} ({{ selectedValues.step_5.color.name }})</p>
                                </div>
                                <div class="flex" v-if="selectedValues.step_5.layer">
                                    <p>{{ $t('Layers') }}:</p>
                                    <p class="ml-2">{{ selectedValues.step_5.layer.name }}</p>
                                </div>
                                <div class="flex" v-if="selectedValues.step_5.mountingScrewLength">
                                    <p>{{ $t('Mounting screw length') }}:</p>
                                    <p class="ml-2">{{ selectedValues.step_5.mountingScrewLength.name }}</p>
                                </div>
                                <div class="flex" v-if="selectedLedModule">
                                    <p>{{ $t('LED module type') }}:</p>
                                    <p class="ml-2">{{ selectedLedModule.name }} - {{ selectedLedModule.max_leds_per_module }} {{ $t('LEDs') }}</p>
                                </div>
                                <div class="flex" v-if="selectedValues.general.chosenConfiguration.total_diodes">
                                    <p>{{ $t('LED quantity') }}:</p>
                                    <p class="ml-2">{{ selectedValues.general.chosenConfiguration.total_diodes }}</p>
                                </div>
                                <div class="flex lens w-100">
                                    <p>{{ $t('Lens') }}:</p>
                                    <div class="flex-column flex-column-start">
                                        <span class="left-adjustment">{{ selectedValues.step_2.lens.name }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="base-attribute complete-order">
                    <div class="flex relative-pos">
                        <span class="button button-primary show-reserve-tooltip" v-if="!isAuthenticated" @click="checkIfLogginNeeded('reserve')">
                            {{ $t('Login to reserve article') }}
                            <div class="typical-attribute-tooltiptext">
                                {{ $t('Saves product configuration and generates an article that can be used to order configured product.') }}
                            </div>
                        </span>
                        <span class="article button button-primary show-reserve-tooltip"
                            v-if="!reactiveGeneralValues.general.article && isAuthenticated"
                            @click="checkIfLogginNeeded('reserve')"
                            :class="{'article-created': reactiveGeneralValues.general.article}"
                        >
                            <b> {{ $t('Reserve article') }}</b>
                            <div class="typical-attribute-tooltiptext">
                                {{ $t('Saves product configuration and generates an article that can be used to order configured product.') }}
                            </div>
                        </span>
                        <span class="button button-primary show-reserve-tooltip" v-if="isAuthenticated && canAddToCart" @click="checkIfLogginNeeded('cart')">
                            <b>{{ $t('Add to cart') }}</b>
                        </span>
                    </div>
                    <span class="button button-primary" v-if="reactiveGeneralValues.general.article && isAuthenticated" @click="goToMyProducts()">{{ $t('My products') }}</span>
                </div>
                <div class="base-attribute flex-between bg-white py-2 base-attribute-row">
                    <div class="mx-3 reset-form "  @click="goToConfiguratorSelectPage()">
                        <p>{{ $t('Start over') }}</p>
                    </div>
                    <div class="reset-form width-auto" v-if="!reactiveGeneralValues.general.article" @click="editConfiguration()">
                            <p> {{ $t('Edit configuration') }}</p>
                    </div>
                    <div class="mx-3 reset-form asana-message" :class="{ 'asana-report-sent': selectedValues.general.asanaReportSent }" @click="openIssueConfiguratorPopup = !openIssueConfiguratorPopup">
                        <p class="text-right">{{ $t('Report issue') }}</p>
                    </div>
                </div>
            </div>
            <IssueReportPopup
                :visible="openIssueConfiguratorPopup"
                :userComment="userComment"
                @close="openIssueConfiguratorPopup = !openIssueConfiguratorPopup"
                @sendReport="sendReport"
            />
        </div>
        <DatasheetDownloadModal
            :visible="datasheetModal"
            :params="datasheetForm"
            :event="'product_configurator_datasheet'"
            :event_data="{
                event_label: 'downloadDatasheet',
                event_category: 'productConfigurator'
            }"
            @close="datasheetModal = !datasheetModal"
        />
        <BasicPopup
            :visible="openConfiguratorPopup"
            :fields="fields"
            :type="configuratorPopupType"
            @close="openConfiguratorPopup = !openConfiguratorPopup"
            @reserveArticle="reserve()"
            @addToCart="addToCart()"
        />
    </div>
  </template>
<script>
import DatasheetDownloadModal from '~/components/Datasheet/DatasheetDownloadModal';
import BasicPopup from '~/components/Common/ConfiguratorPopup.vue';
import { useProductConfigurator } from '@/modules/vizulo/productConfigurator';
import dataHelpers from '@/modules/vizulo/productConfigurator/dataHelpers';
import {
	ref,
	computed,
	useRouter,
	useContext,
	defineComponent,
} from '@nuxtjs/composition-api';
import { useVizulo, useCart } from '~/composables';
import { useCustomerStore } from '~/stores/customer';
import ReadMore from '~/components/Common/ReadMore.vue';
import axios from 'axios';
import IssueReportPopup from '~/components/Common/IssueReportPopup.vue';
import { event } from 'vue-gtag';

export default defineComponent({
	name: 'Step_6',
	components: {
		DatasheetDownloadModal,
		BasicPopup,
		ReadMore,
		IssueReportPopup,
	},
	props: {
		activeStep: String,
		isNextStep: Boolean,
		isMobileView: Boolean,
		storeCode: String
	},
	data: () => (
		{
			copied: false
		}
	),
	setup(props, { emit }) {
		const { productConfigurator, loading, selectedValues, reserveArticle, updateSelectedValues, createBugReportInAsana, getTenderText } = useProductConfigurator();
		const { getApiUrl, getApiVizuloStorage } = useVizulo();
		const customerStore = useCustomerStore();
		const step = 'step_6';
		const datasheetModal = ref(false);
		const datasheetForm = ref({});
		const openConfiguratorPopup = ref(false);
		const configuratorPopupType = ref('reserve');
		const { localePath } = useContext();
		const apiUrl = getApiUrl();
		const router = useRouter();
		const apiVizuloStorageUrl = getApiVizuloStorage();
		const currentOpenedFile = ref('');
		const loadingInfo = ref(false);
		const fields = ref({});
		const { getSelectedLedModuleId, getProductVersionIds, mapDimmingToDatasheet, getSocketLocations } = dataHelpers();
		const activeImagePath = ref('');
		const { addItem: addItemToCart, canAddToCart } = useCart();
		const userComment = ref({});
		const openIssueConfiguratorPopup = ref(false);
		const isAuthenticated = computed(() => Boolean(customerStore.user?.firstname));
		const midnightDimmingId = 41;
		const midnightDimming_DALI = 69;

		const reactiveProductConfigurator = computed(() => {
			return productConfigurator.value.step_6 ?? [];
		});
		const modelNameString = computed(() => {
			if (selectedValues.value.general.modelName.length > 0) {
				if (selectedValues.value.step_6.productImagePath) {
					activeImagePath.value = selectedValues.value.step_6.productImagePath;
				}

				return selectedValues.value.general.modelName.replaceAll('_', ' ');
			}

			return '';
		});

		const reactiveGeneralValues = computed(()=>{
			return selectedValues.value ?? [];
		})

		const selectedLedModule = computed(() => {
			return selectedValues.value.step_4.ledModuleTypes.find((item) => {
				return item.id == getSelectedLedModuleId(selectedValues.value);
			})
		})

		const fillDatasheet = () => {
			let params = {
				model_name: selectedValues.value.general.modelName.replaceAll('_', ' '),
				article: selectedValues.value.general.article ?? 0,
				country: props.storeCode,
				version_no: selectedValues.value.step_6.versionNumber,
				technical_information: {}
			};

			let override = {
				'Luminous flux': selectedValues.value.general.chosenConfiguration.flux + ' lm' + ' ±' + (selectedValues.value.general.diode.flux_tolerance ?? 7) + '%',
				'Luminous efficacy': selectedValues.value.general.chosenConfiguration.luminous_efficacy + ' lm/W',
				'Power factor': selectedValues.value.general.chosenConfiguration.power_factor,
			};

			if (selectedValues.value.step_2.constantLightOutput) {
				override['Initial input power'] = selectedValues.value.general.chosenConfiguration.power + ' W';
			}

			if (selectedValues.value.step_2.constantLightOutput && selectedValues.value.step_2.initialOutput >= 80) {
				override['Constant light output'] = 'Yes';
				override['Lumen depreciation'] = `L100 after ${selectedValues.value.step_2.lifeTime} h`;
			} else if (selectedValues.value.general.diode) {
				override['Lumen depreciation'] = `L${selectedValues.value.general.diode.lumen_depreciation} after ${selectedValues.value.general.diode.lifetime} h`;
			}

			setSocketInformation(override);

			if (selectedValues.value.step_5.layer) {
				override['Increased corrosion resistance'] = capitalizeFirstLetter(selectedValues.value.step_5.layer.name);
			}

			if (selectedValues.value.step_3.thermalProtectionNTC) {
				override['LED module thermal protection w/ NTC'] = 'Yes';
			}

			if (selectedValues.value.step_5.mountingScrewLength) {
				override['Mounting screw length'] = capitalizeFirstLetter(selectedValues.value.step_5.mountingScrewLength);
			}

			if (selectedValues.value.step_3.dimming.id == midnightDimming_DALI || selectedValues.value.step_3.dimming.id == midnightDimmingId) {
				override['Dimming schedule'] = mapDimmingToDatasheet(selectedValues.value.step_3.dimmingParameters.midnight, props.storeCode);
			}

			if (selectedValues.value.step_3.dimming.id === 70 && getSocketLocations(selectedValues.value).length  !== 0) {
			} else {
				override['Dimming'] = capitalizeFirstLetter(selectedValues.value.step_3.dimming.name);
			}

			params.technical_information['override'] = override;
			datasheetForm.value = params;
		}

		const setSocketInformation = (override) => {
			let socketName = '';

			for (let socket in selectedValues.value.step_3.sockets) {
				if ( selectedValues.value.step_3.sockets[socket].name !== 'none') {
					if (socketName === selectedValues.value.step_3.sockets[socket].name) {
						if (override['Socket'] && override['Socket'].length > 0) {
							override['Socket'] = `${override['Socket']} and ${socket}`;
						} else {
							override['Socket'] = `${capitalizeFirstLetter(selectedValues.value.step_3.sockets[socket].name)} on ${socket}`;
						}
					} else {
						if (override['Socket'] && override['Socket'].length > 0) {
							override['Socket'] = `${override['Socket']}, ${capitalizeFirstLetter(selectedValues.value.step_3.sockets[socket].name)} on ${socket}`;
						} else {
							override['Socket'] = `${capitalizeFirstLetter(selectedValues.value.step_3.sockets[socket].name)} on ${socket}`;
						}
					}

					socketName = selectedValues.value.step_3.sockets[socket].name;
				}
			}
		}

		const editConfiguration = () => {
			updateSelectedValues('general', 'editMode', true);

			if (selectedValues.value.step_1.productVersions.length && selectedValues.value.step_1.console) {
				emit('changeStep', 'step_1');
			} else {
				emit('changeStep', 'step_2');
			}
		}

		const capitalizeFirstLetter = (value) => {
			if (value[0]) {
				return value[0].toUpperCase() + value.slice(1);
			}
		}

		const downloadLdtFile = (type) => {
			const preparedData = {
				source: 'C',
				type: type,
				product_id: selectedValues.value.general.beeProductId,
				diode_bin_id: selectedValues.value.step_4.binId ?? 0,
				diode_id: selectedValues.value.general.diode.id ?? 0,
				cri_id: Number(selectedValues.value.step_2.cri.id),
				color_temperature_id: Number(selectedValues.value.step_2.colorTemperature.id),
				led_module_id: Number(getSelectedLedModuleId(selectedValues.value)),
				lens_id: selectedValues.value.step_2.lens.id ?? 0,
				total_diodes: selectedValues.value.general.chosenConfiguration.total_diodes ?? 0,
				power: selectedValues.value.general.chosenConfiguration.power,
				flux: selectedValues.value.general.chosenConfiguration.flux,
				article: selectedValues.value.general.article ?? 0,
			}

			if (type === 'ldt') {
				event('product_configurator_ldt', {
					event_label: 'downloadLDT',
					event_category: 'productConfigurator'
				});
			} else {
				event('product_configurator_ies', {
					event_label: 'downloadIES',
					event_category: 'productConfigurator'
				});
			}

			axios({
				url: apiUrl + 'getPhotometricFile',
				method: 'POST',
				data: preparedData,
				headers: {
					'Content-Type': 'application/json',
				},
				responseType: 'arraybuffer',
			}).then((res) => {
				if (res.status == 200 && res.data.byteLength) {
					let blob = new Blob([res.data], { type: res.headers['content-type'] });
					let link = document.createElement('a');
					link.href = window.URL.createObjectURL(blob);

					const reader = new FileReader();

					reader.onload = () => {
						if (reader.result.length > 0) {
							let text = reader.result.split('\r\n');

							if (type == 'ies') {
								for (let row of text) {
									if (row.includes('[FILENAME]')) {
										link.download = row.replace('[FILENAME] ', '') + '.' + type;
										break;
									}
								}
							} else {
								link.download = text[10] + '.' + type;
							}

							link.click();
						}
					};

					reader.readAsText(blob);
				} else {
					console.error('could not find files.');
				}
			});
		}

		const openSpecificPopUp = (element) => {
			if (element === 'datasheet') {
				currentOpenedFile.value = 'datasheet';
				fillDatasheet();
				datasheetModal.value = true;
			} else if (element === 'ldt' && selectedValues.value.step_6.filesAvailable && selectedValues.value.step_6.filesAvailable.includes('ldt')) {
				currentOpenedFile.value = 'ldt';
				downloadLdtFile(currentOpenedFile.value);
			} else if (element === 'ies' && selectedValues.value.step_6.filesAvailable && selectedValues.value.step_6.filesAvailable.includes('ies')) {
				currentOpenedFile.value = 'ies';
				downloadLdtFile(currentOpenedFile.value);
			}
		}

		const sendReport = (sentWithSubmitButton) => {
			createBugReportInAsana(userComment.value.message ?? '', sentWithSubmitButton);
			openIssueConfiguratorPopup.value = false;
		}

		const goToConfiguratorSelectPage = () => {
			updateSelectedValues('general', 'inProcessOfLogin', false);
			updateSelectedValues('general', 'editMode', false);
			emit('goToConfiguratorSelectPage');
		}

		const checkIfLogginNeeded = (type) => {
			configuratorPopupType.value = canAddToCart.value ? type : 'reserve';

			if (!reactiveGeneralValues.value.general.article) {
				updateSelectedValues('general', 'inProcessOfLogin', true);
			}

			if (!isAuthenticated.value) {
				router.push(localePath('/login'));
			} else {
				openConfiguratorPopup.value = true;
			}

			openConfiguratorPopup.value = true;
		}

		const reserve = () => {
			reserveArticle(apiUrl, fields.value.projectReference, props.storeCode).then((result) => {
				if (result.id && Number(result.id ) === 0) {
					router.push(localePath('/login'));
				}
			});

			if (configuratorPopupType.value == 'reserve') {
				openConfiguratorPopup.value = false;
			}
		}

		const addToCart = async () => {
			if (!reactiveGeneralValues.value.general.article && configuratorPopupType.value == 'cart') {
				reserveArticle(apiUrl, fields.value.projectReference, props.storeCode).then((result) => {
					if (result.id && Number(result.id ) === 0) {
						router.push(localePath('/login'));
					} else {
						addItemToCart({
							product: reactiveGeneralValues.value.product,
							quantity: 1,
							configuration_options: [
								{
									code: 'project_reference',
									value: fields.value.projectReference,
								},
								{
									code: 'article',
									value: selectedValues.value.general.article,
								},
								{
									code: 'model_name',
									value: selectedValues.value.general.modelName.replaceAll('_', ' '),
								},
							]
						});

						openConfiguratorPopup.value = false;
						router.push(localePath('/checkout/cart'));
					}
				});
			}

			openConfiguratorPopup.value = false;
		};

		const goToMyProducts = () => {
			router.push(localePath('/members/articles'));
		}

		const tenderText = () => {
			let res = getTenderText(apiUrl, props.storeCode);
		}

		const copyText = () => {
			navigator.clipboard.writeText(selectedValues.value.step_6.tenderText);
		}

		const getIsolineFilePath = (filePath) => {
			if (filePath) {
				return filePath.replace('.png', '_trim.png');
			} else {
				return '';
			}
		}

		return {
			openSpecificPopUp,
			goToConfiguratorSelectPage,
			downloadLdtFile,
			reserveArticle,
			checkIfLogginNeeded,
			editConfiguration,
			getProductVersionIds,
			goToMyProducts,
			reserve,
			getSelectedLedModuleId,
			sendReport,
			tenderText,
			copyText,
			addToCart,
			getIsolineFilePath,
			selectedLedModule,
			isAuthenticated,
			apiVizuloStorageUrl,
			reactiveProductConfigurator,
			loading,
			selectedValues,
			datasheetModal,
			datasheetForm,
			openConfiguratorPopup,
			configuratorPopupType,
			reactiveGeneralValues,
			modelNameString,
			currentOpenedFile,
			loadingInfo,
			fields,
			canAddToCart,
			userComment,
			openIssueConfiguratorPopup,
			activeImagePath,
			event
		};
	}
});
</script>
<style lang="scss" scoped>
    .base-attribute{
        background: var(--c-white-blue);
        padding: 0 2rem !important;
        border: 1px solid var(--c-light-gray);
        border-bottom: none;

        &:last-child {
            border: none;
        }
    }

    .py-2 {
        padding: 0.5rem 0 !important;
    }

    .order-info {
        border: 1px solid var(--c-light-gray);
        border-top: none;
        border-bottom: none;
        display: flex;
        flex-direction: column;

        @media (min-width: 999px) {
            flex-direction: row;
        }

        .info{
            display: flex;
            flex-direction: column;
        }

        .product-info {
            & > div {
                width: 100%;
                padding: 0 1rem 1rem 1rem;
                border-bottom: 1px solid var(--c-light-gray);
                border-top: none;
                border-left: none;
            }

            flex-direction: column;

            .w-8 {
                width: 8rem;
            }

            p {
                line-break: auto;
            }
        }

        .general-info {
            width: 100%;
            flex-direction: column;
            border-top: 1px solid var(--c-light-gray);

            @media (min-width: 999px) {
                width: 50%;
                border-top: none;
            }
        }

        .article {
            border-right: none !important;
        }
    }

    .base-attribute-row {
        flex-direction: column !important;

        @media (min-width: 360px) {
            flex-direction: row !important;
        }

    }

    .product-image {
        width: 100%;
        border-right: 1px solid var(--c-light-gray);

        @media (min-width: 999px) {
            width: 50%;
        }

        .flex-center {
            height: 420px;
        }

        img {
            height: inherit;
        }

        & > div {
            margin: 1rem;
        }
    }

    .flex {
        & > div > div {
            border-bottom: none;
            border-top: none;
            width: 100%;
            padding: 1rem;
        }
    }

    .attributes {
        padding: 1rem;

        .ml-2 {
            &:first-letter {
                text-transform: capitalize;
            }
        }

        .flex {
            p {
                width: 50%;
                margin: 0.3rem;
            }
        }
    }

    .description {
        border-top: 1px solid var(--c-light-gray);
        border-bottom: none;
        width: 40%;
    }

    .options {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .option {
        list-style: none;
        font-size: 14px;
        line-height: 18px;
        background-color: var(--c-black);
        color: var(--c-white);
        cursor: pointer;
        font-family: var(--font-family--primary);
        padding: 9px 12px;
        border: 1px solid var(--c-black);
        transition: all ease-in 300ms;
        display: inline-block;
        margin: 6px 15px 6px 0;
        border-radius: 20px;

        span {
            white-space:nowrap;
            font-weight: normal;
        }

        &:focus {
            outline: none;
        }
    }

    .inactive-sheet {
        background-color: #0000004d;
        border: 1px solid #0000004d;
        cursor: default;

        &:hover {
            background-color: #0000004d !important;
            border: 1px solid #0000004d !important;
        }
    }

    .circle {
        padding: 2rem;
        border-radius: 50%;
        background: var(--c-black);
        width: 80px;
        height: 80px;
        justify-content: center;

        svg {
            color: var(--c-white) !important;
        }
    }

    .flex-column {
        div {
            font-size: 20px;
        }

        h2 {
            font-weight: bold;
            font-size: 28px;
        }
    }

    .cover {
        width: 100vw;
        height: 100vh;
        background: var(--c-lighter-gray-transparent);
        position: absolute;
        top: 0;

        .popup {
            background: var(--c-white);
            position: relative;
            padding: 0 3rem;
        }

        .close {
            position: absolute;
            top: 0;
            right: 0;
            padding: 1.2rem;
            cursor: pointer;
        }

        .option {
            font-size: 22px;
            padding: 22px 24px;
            border-radius: 40px;
            margin-right: 0;
        }

        .or {
            align-items: center;
            justify-content: center;

            div {
                width: 100px;
                border-bottom: 1px solid var(--c-black);
            }

            span {
                position: relative;
                margin: 0 10px;
                bottom: 3px;
            }
        }
    }

    .between {
        justify-content: space-between;
    }

    .b-right {
        border-left: 1px solid var(--c-light-gray);
    }

    .complete-order {
        background-color: var(--c-lighter-gray);
        align-items: center !important;
        justify-content: center !important;

        span {
            &:not(.reset) {
                font-size: 18px;
                color: #fff;
                background-color: #0B0B0C;
                border: 1px solid #0B0B0C;
                text-decoration: none;
                margin: 2rem 2rem;
                padding: 0.8rem 1.5rem;
                border-radius: 40px;
                cursor: pointer;
            }
        }

        .button-primary {
            margin: 1rem 0.5rem !important;
            font-size: 14px !important;
            padding: 0.6rem 1rem !important;

            @media (min-width: 440px) {
                font-size: 14px !important;
                margin: 1.5rem 1.5rem !important;
                padding: 0.6rem 1rem !important;
            }

            @media (min-width: 550px) {
                font-size: 18px !important;
                margin: 2rem 2rem !important;
                padding: 0.8rem 1.5rem !important;
            }
        }
    }

    .lens {
        padding: 0 !important;
        position: relative;

        img {
            height: 36px;
            margin-right: 1rem;
            margin-top: 0.4rem;
            cursor: pointer;
        }

        div {
            width: 50%;
        }

        .big-image {
            position: absolute;
            top: -10px;
            left: 250px;
            width: 200px;
            display: none;
            cursor: default;

            img {
                height: 120px;
                width: 200px;
            }
        }
        &:hover .big-image {
            display:block;
        }
    }

    .color-indicator-container {
        position: absolute;
        display: flex;
        align-items: center;
        margin: 0.5rem;

        p {
            margin: 0 0 0 0.5rem;
        }

        .color-indicator {
            height: 2rem;
            width: 2rem !important;
            border-radius: 50%;
        }
    }

    .gallery-bar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0 1rem;

        .original-image {
            cursor: pointer;
            height: 2.5rem;
            width: 2.8rem;

            img {
                height: auto;
                max-height: 100%;
            }
        }
        .active {
            border: 1px solid var(--c-gray);
        }
    }


    ::v-deep input {
        border: none !important;
        border-radius: 0 !important;
    }

    ::v-deep .sf-input__wrapper {
        border-bottom: 1px solid var(--c-gray) !important;
    }

    .article {
        b {
            font-weight: normal !important;
            text-transform: none;
            font-family: var(--font-family--primary);
        }

        .small-button {
            top: -170% !important;
        }
    }

    .article-created {
        cursor: default !important;
    }

    .small {
        font-size: 14px;
        line-height: 14px;
        padding: 4px 12px;
        border-radius: 49px;
        margin-left: 0.5rem;
    }

    .bg-white {
        border-top: none;
        align-items: center !important;
    }

    .spacer {
        width: 10rem;
        border-bottom: 1px solid var(--c-light-gray);

        @media (min-width: 1200px) {
            display: none;
        }
    }

    .width-auto {
        width: auto !important;
    }

    .flex-start {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .w-9 {
        width: 50%;
    }

    .less-visible {
        font-family: var(--font-family--primary-italic);
        color: var(--_c-gray-primary);
    }

    .left-adjustment {
        position: relative;
        left: 1px;
    }

    .inherit-height {
        height: inherit !important;
    }

    .typical-attribute-tooltiptext {
        top: -60% !important;
        width: 350px !important;
        padding: 1rem 1.5rem !important;
        background: var(--c-white) !important;
        left: 50% !important;;
        transform: translate(-50%, 0%);
        border: 1px solid var(--_c-gray-primary) !important;
        color: var(--c-black) !important;
        border-radius: 1rem !important;
        font-size: 16px !important;
        box-shadow: 3px 4px 7px 0px rgba(0,0,0,0.53);
        -webkit-box-shadow: 3px 4px 7px 0px rgba(0,0,0,0.53);
        -moz-box-shadow: 3px 4px 7px 0px rgba(0,0,0,0.53);
    }

    .show-reserve-tooltip:hover + .typical-attribute-tooltiptext {
        visibility: visible !important;
    }

    .show-reserve-tooltip:hover .typical-attribute-tooltiptext {
        visibility: visible !important;
        color: var(--c-black);
    }

</style>